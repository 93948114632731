<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-11 text-uppercase">
          RFP Details
        </div>

        <div v-if="canEditRfq" class="col-sm-1 text-right-not-xs">
          <edit-rfq-project-details
            :rfq="rfq"
            :on-save="updateProjectDetails">
          </edit-rfq-project-details>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <loading-section name="updateProjectDetails">
        <div class="row bottom-30">
          <div class="col-sm-6">
            <svg-icon name="note-with-dollar-sign" class="base-icon stroke right-5"></svg-icon>
            <div class="bold-weight inline-block bottom-2">RFP Name</div>
            <div class="left-27">{{ rfq.name || `Reference #${rfq.id}` }}</div>
          </div>

          <div class="col-sm-6 top-30-xs">
            <svg-icon name="note-with-hashtag" class="base-icon stroke right-5"></svg-icon>
            <div class="bold-weight inline-block bottom-2">RFP Reference Number</div>
            <div class="left-27">{{ rfq.id }}</div>
          </div>
        </div>

        <div class="row bottom-30">
          <div class="col-sm-6">
            <svg-icon name="clients" class="right-5 base-icon"></svg-icon>
            <div class="bold-weight inline-block bottom-2">RFP Submitted By</div>
            <div class="left-27">{{ rfq.creatorFullName }}</div>
          </div>

          <div v-if="hasPracticeGroups" class="col-sm-6 top-30-xs">
            <svg-icon name="court" class="base-icon right-5"></svg-icon>
            <div class="bold-weight inline-block bottom-2">Practice Group</div>
            <div class="left-27">{{ practiceGroups }}</div>
          </div>

          <div v-else class="col-sm-6 top-30-xs">
            <svg-icon name="clockDue" class="base-icon right-5"></svg-icon>
            <div class="bold-weight inline-block bottom-2">Responses Due By</div>
            <div class="left-27">{{ formatDate(rfq.dueDate) }}</div>
          </div>
        </div>

        <div class="row bottom-30" v-if="hasBudget || hasPracticeGroups">
          <div v-if="hasBudget" class="col-sm-6">
            <svg-icon name="dollar" class="base-icon right-5"></svg-icon>
            <div class="bold-weight inline-block bottom-2">Budget</div>
            <div class="left-27">{{ budget }}</div>
          </div>

          <div v-if="hasPracticeGroups" class="col-sm-6 top-30-xs">
            <svg-icon name="clockDue" class="base-icon right-5"></svg-icon>
            <div class="bold-weight inline-block bottom-2">Responses Due By</div>
            <div class="left-27">{{ formatDate(rfq.dueDate) }}</div>
          </div>
        </div>

        <div>
          <div class="bold-weight bottom-5">Description of Work</div>
          <div>
            <span class="simple-text">{{ rfq.descriptionOfWork || '—' }}</span>
          </div>
        </div>

        <div v-if="hasCounterpartyDetails" class="top-20">
          <div class="bold-weight bottom-5">Any relevant opposing party/counterparty details, including counsel</div>
          <div>
            <span class="simple-text">{{ rfq.counterpartyDetails }}</span>
          </div>
        </div>

        <div v-if="hasPreferredRateStructure" class="top-20">
          <div class="bold-weight bottom-5">Preferred Rate Structure</div>
          <div>
            <span class="simple-text">{{ rfq.preferredRateStructure }}</span>
          </div>
        </div>

        <div v-if="hasEstimatedTimeline" class="top-20">
          <div class="bold-weight bottom-5">Estimated Timeline</div>
          <div>
            <span class="simple-text">{{ rfq.estimatedTimeline }}</span>
          </div>
        </div>

        <div v-if="hasAdditionalInformation" class="top-20">
          <div class="bold-weight bottom-5">Additional Information</div>
          <div>
            <span class="simple-text">{{ rfq.additionalInformation }}</span>
          </div>
        </div>

        <div v-if="hasSupportingDocuments" class="top-20">
          <div class="bold-weight bottom-5">
            Supporting Documents ({{ rfq.supportingDocuments.length }})
          </div>

          <ul class="compact">
            <li
              v-for="(doc, index) in rfq.supportingDocuments"
              class="list-spacing-2"
              :key="index">
              <a :href="doc.url" class="bold-weight" target="_blank" rel="noopener">{{ doc.name }}</a>
            </li>
          </ul>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import { compact } from 'lodash';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import DateFilter from 'vue-app/shared/lib/date-filter';
import EditRfqProjectDetails from 'vue-app/scout/client/rfqs/edit-rfq-project-details.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'RfqProjectDetails',

  components: {
    EditRfqProjectDetails,
    LoadingSection
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    updateProjectDetails: {
      type: Function,
      required: true
    },

    canEditRfq: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    hasBudget() {
      return this.rfq.budgetMin || this.rfq.budgetMax;
    },

    budget() {
      return compact([CurrencyFilter.filter(this.rfq.budgetMin), CurrencyFilter.filter(this.rfq.budgetMax)]).join(' - ');
    },

    hasCounterpartyDetails() {
      return this.rfq.counterpartyDetails?.length > 0;
    },

    hasPreferredRateStructure() {
      return this.rfq.preferredRateStructure?.length > 0;
    },

    hasEstimatedTimeline() {
      return this.rfq.estimatedTimeline?.length > 0;
    },

    hasAdditionalInformation() {
      return this.rfq.additionalInformation?.length > 0;
    },

    hasSupportingDocuments() {
      return this.rfq.supportingDocuments?.length > 0;
    },

    hasWorkspacePracticeGroups() {
      return this.rfq.workspacePracticeGroups.length > 0;
    },

    hasPracticeGroups() {
      return this.hasWorkspacePracticeGroups ? this.rfq.practiceGroups.length : this.rfq.assignedClientPracticeGroup;
    },

    practiceGroups() {
      return this.hasWorkspacePracticeGroups ? this.sortedPracticeGroups : this.rfq.assignedClientPracticeGroup;
    },

    sortedPracticeGroups() {
      if (this.rfq.practiceGroups.length === 0) { return '-'; }

      return this.rfq.practiceGroups.map(practiceGroup => practiceGroup.name).sort().join(', ');
    }
  },

  methods: {
    formatDate(date) {
      return DateFilter.filterDate(date);
    }
  }
};
</script>

<style scoped lang="scss">
  .left-27 {
    margin-left: 27px;
  }
</style>
