<template>
  <div class="row bottom-20">
    <div class="col-sm-4 ">
      <label :class="['semibold-weight', { 'no-margin': !editing }]">Bar Licenses</label>
    </div>

    <div v-show="editing" class="col-sm-8 top-10-xs">
      <div v-for="(bar, index) in requiredBars" :class="`deselect-input bar-country-container-${index}`" :key="`required-bar-${index}`">
        <div class="row tight-columns">
          <div class="col-sm-6 bottom-15">
            <typeahead-vertical
              :disabled="index !== requiredBars.length - 1"
              :id="`bar-country-${index}`"
              :initial-value="bar.country"
              :options="barCountryNames"
              :force-select="true"
              placeholder="Enter country"
              :hide-icon="true"
              rules="required"
              v-model="bar.country"
              @input="updateBars">
            </typeahead-vertical>
          </div>

          <div class="col-sm-6 bottom-15">
            <typeahead-vertical
              :disabled="index !== requiredBars.length - 1 || bar.country !== 'United States'"
              :id="`bar-state-${index}`"
              :initial-value="bar.state"
              :options="barStateNames"
              :force-select="true"
              :placeholder="statePlaceHolder(bar.country)"
              :hide-icon="true"
              :rules="bar.country === 'United States' ? 'required' : null"
              v-model="bar.state"
              @input="updateBars">
            </typeahead-vertical>

            <div @click="removeBar(index)">
              <svg-icon class="deselect-icon for-tight-column-input" name="x3"></svg-icon>
            </div>
          </div>
        </div>
      </div>

      <button type="button" :disabled="!allBarsValid" class="right-20 small-btn" @click="addBar">+ Bar License</button>
    </div>

    <div v-if="!editing" class="col-sm-8">
      {{ barsDisplay }}
    </div>
  </div>
</template>
<script>
import RfpDataService from 'vue-app/shared/services/rfp-data-service.js';

export default {
  name: 'OpportunityBarLicenses',

  props: {
    editing: {
      type: Boolean,
      default: true
    },

    requiredBars: {
      type: Array,
      required: true
    },

    onAddBar: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      // TODO: Refactor into shared lists
      rfpDataService: new RfpDataService()
    };
  },

  computed: {
    barsDisplay() {
      return this.requiredBars.map(bar => (bar.state || bar.country)).join(', ') || '—';
    },

    barCountriesList() {
      return this.rfpDataService.lists.barCountries.map(c => c.country);
    },

    requiredBarsHaveUnitedStatesWithState() {
      return this.requiredBars.some(
        bar => bar.country === 'United States' && bar.state !== null
      );
    },

    requiredBarCountries() {
      return this.requiredBars.map(b => b.country) || [];
    },

    barCountryNames() {
      if (this.requiredBars.length <= 1) { return this.barCountriesList; }

      return this.barCountriesList.filter(country => {
        if (country === 'United States') {
          return this.requiredBarsHaveUnitedStatesWithState;
        }
        else {
          return !this.requiredBarCountries.includes(country);
        }
      });
    },

    barStatesList() {
      return this.rfpDataService.lists.barStates.map(s => s.name);
    },

    requiredBarStates() {
      return this.requiredBars.map(b => b.state);
    },

    barStateNames() {
      const selectedBarState = [...this.requiredBarStates];
      selectedBarState.splice(-1);
      return this.barStatesList.filter(state => !selectedBarState.includes(state));
    },

    allBarsValid() {
      const invalid = [];

      this.requiredBars.forEach((bar) => {
        if (!bar.country) {
          invalid.push(bar);
        }
        else if (bar.country === 'United States' && !bar.state) {
          invalid.push(bar);
        }
      });
      return !invalid.length;
    }
  },

  methods: {
    updateBars() {
      this.requiredBars.forEach((bar) => {
        if (bar.country && bar.country !== 'United States') { bar.state = null; }
        if (bar.state && !bar.country) { bar.state = null; }
      });
    },

    statePlaceHolder(country) {
      return country === 'United States' ? 'State' : 'N/A';
    },

    removeBar(index) {
      this.requiredBars.splice(index, 1);
    },

    addBar() {
      if (!this.allBarsValid) { return; }

      const defaultCountry = this.requiredBars.length ? null : 'United States';
      const newBar = { country: defaultCountry, state: null };

      this.updateBars();
      this.onAddBar(newBar);
    }
  }
};
</script>

