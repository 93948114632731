<template>
  <priori-modal ref="addLawyersModal" modal-id="add-lawyer-modal" :title="title">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue bold-weight" @click="initModal(openModal)">+ Add Lawyer(s)</button>
    </template>

    <loading-section name="addLawyers">
      <div class="a-form">
        <div class="bottom-30">
          <typeahead-vertical
            ref="lawyersToAddTypeahead"
            label="Enter Lawyer Name"
            id="add-lawyer-selection"
            :allow-enter-keydown="true"
            :options="searchableLawyers"
            option-label-key="fullName"
            :placeholder="isConnecting ? 'Enter name' : 'Search by name'"
            :hide-icon="isConnecting"
            @input="selectLawyer"
            @enterKeydown="selectCustomLawyer">
          </typeahead-vertical>

          <div class="error-text top-5" v-if="displayError">
            {{ errorMessage }}
          </div>

          <div class="tag-list top-10" v-if="hasLawyersToAdd">
            <div class="tag-list-item compact clear delete-variant" v-for="(lawyer, index) in lawyersToAdd" :key="index">
              <span class="right-10">{{ lawyer.fullName || lawyer.lawyerName }}</span>
              <button type="button" class="pseudo-link-blue tag-list-item-delete" @click="removeLawyer(index)"><svg-icon name="x-circle" class="base-icon"></svg-icon></button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <button type="button" class="primary-button-compact" @click="addLawyers">Add to Quote</button>
          </div>

          <div class="col-sm-4 top-20-xs">
            <button type="button" class="secondary-button-compact" @click="closeModal">Cancel</button>
          </div>
        </div>
      </div>
    </loading-section>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import RfqSuggestedLawyer from 'resources/rfq-suggested-lawyer.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'AddLawyersModal',

  components: {
    PrioriModal,
    LoadingSection,
    TypeaheadVertical,
    SvgIcon
  },

  props: {
    selectedLawyerBridgeIds: {
      type: Array,
      required: true
    },

    title: {
      type: String,
      default: 'Add Lawyer(s)'
    },

    optionsAreBridges: {
      type: Boolean,
      default: true
    },

    onLoadLawyers: {
      type: Function,
      required: true
    },

    onAddLawyers: {
      type: Function,
      required: true
    },

    isConnecting: {
      type: Boolean,
      default: false
    },

    firmSuggestedLawyers: {
      type: Array,
      required: true
    },

    clientSuggestedLawyers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      displayError: false,
      errorMessage: '',
      lawyersToAdd: [],
      availableLawyers: [],
      lawyersLoaded: false
    };
  },

  computed: {
    lawyersToAddIds() {
      return this.lawyersToAdd.map(l => l.id);
    },

    nonSearchableLawyerBridgeIds() {
      return this.selectedLawyerBridgeIds.concat(this.lawyersToAddIds);
    },

    nonSearchableLawyerIds() {
      return this.firmSuggestedLawyers.map(l => l.lawyerId).concat(this.lawyersToAddIds);
    },

    searchableLawyers() {
      return this.availableLawyers.filter((lawyer) => {
        if (this.optionsAreBridges) {
          return !this.nonSearchableLawyerBridgeIds.includes(lawyer.id);
        }
        else {
          return !this.nonSearchableLawyerIds.includes(lawyer.id);
        }
      });
    },

    hasLawyersToAdd() {
      return this.lawyersToAdd.length > 0;
    },

    allAddedLawyers() {
      return this.clientSuggestedLawyers.concat(this.firmSuggestedLawyers);
    }
  },

  methods: {
    initModal(openModalFn) {
      this.lawyersToAdd = [];
      this.$refs.lawyersToAddTypeahead.reset();
      openModalFn();

      if (this.isConnecting) {
        this.availableLawyers = [];
        this.lawyersLoaded = true;
        return;
      }

      if (this.lawyersLoaded) { return; }

      LoadingService.loading('addLawyers');
      this.onLoadLawyers()
        .then((lawyers) => {
          this.availableLawyers = lawyers;
          this.lawyersLoaded = true;
        })
        .finally(() => {
          LoadingService.done('addLawyers');
        });
    },

    clearErrors() {
      this.displayError = false;
      this.errorMessage = '';
    },

    showDuplicateError() {
      this.displayError = true;
      this.errorMessage = '* Duplicate lawyer';
    },

    selectLawyer(lawyer) {
      if (!lawyer) { return; }

      if (!this.isDuplicateLawyer(lawyer.fullName)) {
        this.lawyersToAdd.push(lawyer);
        this.clearErrors();
      }
      else {
        this.showDuplicateError();
      }

      this.$refs.lawyersToAddTypeahead.reset();
    },

    selectCustomLawyer(fullName) {
      if (!this.isValidLawyerName(fullName)) { return; }
      this.selectLawyer({ id: null, lawyerName: fullName, fullName: fullName });
    },

    isDuplicateLawyer(fullName) {
      return this.addingLawyerExists(fullName) || this.addedLawyerExists(fullName);
    },

    addingLawyerExists(fullName) {
      const match = fullName.toLowerCase();
      return this.lawyersToAdd.find(lawyer => lawyer.fullName.toLowerCase() === match);
    },

    addedLawyerExists(fullName) {
      let suggestedLawyer = null;

      return this.allAddedLawyers.find(lawyerData => {
        suggestedLawyer = new RfqSuggestedLawyer(lawyerData);
        return suggestedLawyer.fullNameEquals(fullName);
      });
    },

    isValidLawyerName(fullName) {
      return typeof fullName === 'string' && fullName !== '';
    },

    removeLawyer(index) {
      this.lawyersToAdd.splice(index, 1);
      this.$refs.lawyersToAddTypeahead.reset();
    },

    addLawyers() {
      if (!this.hasLawyersToAdd) {
        this.displayError = true;
        this.errorMessage = '* Please select at least one lawyer';
        return;
      }

      this.onAddLawyers(this.lawyersToAdd);
      this.closeModal();
    },

    closeModal() {
      this.lawyersToAdd = [];
      this.clearErrors();
      this.$refs.addLawyersModal.closeModal();
    }
  }
};
</script>
