import Vue from 'vue';
import vueComponents from './vue-components';

/****************/
/*  COMPONENTS  */
/****************/

import MessagingCenter from 'vue-app/mailboxer/messaging-center.vue';

vueComponents
.directive('ngvMessagingCenter', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('MessagingCenter', MessagingCenter));
}]);
