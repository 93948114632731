import { render, staticRenderFns } from "./filter-button.vue?vue&type=template&id=0813b157&scoped=true"
import script from "./filter-button.vue?vue&type=script&lang=js"
export * from "./filter-button.vue?vue&type=script&lang=js"
import style0 from "./filter-button.vue?vue&type=style&index=0&id=0813b157&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0813b157",
  null
  
)

export default component.exports