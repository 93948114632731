<template>
  <div class="resource-list">
    <div class="list-header">
      <div class="row tight-columns">
        <div class="col-sm-7">
          <div class="row tight-columns">
            <div
              v-for="(column, index) in columnGroupOne"
              :class="[column.class, 'vertical-center']"
              :key="`col-one-${index}`">
              <dynamic-order-arrows
                :order="order"
                :order-by="column.key"
                :initial-order="initialSortOrder(column.key)"
                :on-sort="sort"
                :reverse="!!column.reverse"
                v-if="column.sortable">
                {{ column.label }}
              </dynamic-order-arrows>
            </div>
          </div>
        </div>

        <div class="col-sm-5">
          <div class="row tight-columns">
            <div
              v-for="(column, index) in columnGroupTwo"
              :class="[column.class, 'vertical-center']"
              :key="`col-two-${index}`">
              <dynamic-order-arrows
                :order="order"
                :order-by="column.key"
                :initial-order="initialSortOrder(column.key)"
                :on-sort="sort"
                :reverse="!!column.reverse"
                v-if="column.sortable">
                {{ column.label }}
              </dynamic-order-arrows>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasRFQs">
      <div class="list-item" v-for="rfq in rfqs" :key="rfq.id">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-7">
            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-3">
                {{ formatDate(rfq.dateSent) || '—' }}
              </div>

              <div class="col-sm-5">
                <div class="text-ellipsis">
                  <a class="bold-weight blue-link" :href="nameLink(rfq)">{{ rfqName(rfq) }}</a>
                </div>
                <div>
                  Reference #{{ rfq.id }}
                </div>
              </div>

              <div class="col-sm-4 text-ellipsis">
                {{ rfq.creatorFullName }}
              </div>
            </div>
          </div>

          <div class="col-sm-5">
            <div class="row tight-columns vertical-center-not-xs">
              <div :class="['col-sm-4', { 'red-text': isPastDue(rfq) }]">
                {{ formatDate(rfq.dueDate) || '—' }}
              </div>

              <div class="col-sm-6 top-10-xs">
                <status-badge :rfq="rfq" class="bottom-5"></status-badge>
                <response-count v-if="hasStatus(rfq, 'sent')" :rfq="rfq"></response-count>
              </div>

              <div class="col-sm-2 bold-weight text-right-not-xs fixed-width-125px-i">
                <dropdown-menu :compact="true">
                  <li v-if="rfq.status === 'draft'" class="menu-item" role="menuitem">
                    <a :href="`/scout-company/rfps/${rfq.id}/edit`">Edit</a>
                  </li>

                  <li v-if="rfq.status !== 'draft'" class="menu-item" role="menuitem">
                    <a :href="`/scout-company/rfps/${rfq.id}`">View</a>
                  </li>

                  <li v-if="rfq.status === 'draft' || rfq.status === 'closed' || rfq.status === 'sent' || rfq.status === 'firm_selected'" class="menu-item" role="menuitem">
                    <change-rfq-status-modal
                      :rfq="rfq"
                      :change-rfq-status="changeRfqStatus"
                      :on-delete="onDelete">
                    </change-rfq-status-modal>
                  </li>
                </dropdown-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <priori-pagination
        class="scout-pagination top-15"
        :current-page="currentPage"
        :total-entries="totalRfqCount"
        :per-page="perPage"
        @change="changeRFQPage">
      </priori-pagination>
    </div>

    <div v-else>
      <div class="list-item empty gray-text size-text-16px">
        No Results
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import DateFilter from 'vue-app/shared/lib/date-filter';

import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import StatusBadge from 'vue-app/scout/client/rfqs/status-badge.vue';
import ResponseCount from 'vue-app/scout/client/rfqs/response-count.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import ChangeRfqStatusModal from 'vue-app/scout/client/rfqs/change-rfq-status-modal.vue';

export default {
  name: 'ClientRfqIndexTable',

  components: {
    PrioriPagination,
    StatusBadge,
    ResponseCount,
    DropdownMenu,
    ChangeRfqStatusModal
  },

  props: {
    rfqs: {
      type: Array,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    changePage: {
      type: Function,
      required: true
    },

    totalRfqCount: {
      type: Number,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    },

    changeRfqStatus: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      order: { 'date_sent': 'desc' },
      columnGroupOne: [
        { key: 'date_sent', label: 'Sent', class: 'col-sm-3', sortable: true, reverse: false },
        { key: 'id', label: 'RFP Name', class: 'col-sm-5', sortable: true, reverse: true },
        { key: 'creator_full_name', label: 'Submitted By', class: 'col-sm-4', sortable: true, reverse: true }
      ],
      columnGroupTwo: [
        { key: 'due_date', label: 'Due', class: 'col-sm-4', sortable: true, reverse: false },
        { key: 'status', label: 'Status', class: 'col-sm-6', sortable: true, reverse: true },
        { key: 'actions', label: 'Actions', class: 'col-sm-2', sortable: false }
      ]
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    hasRFQs() {
      return this.rfqs.length > 0;
    }
  },

  methods: {
    changeRFQPage(page) {
      this.changePage(page);
    },

    nameLink(rfq) {
      if (rfq.status === 'draft') {
        return `/scout-company/rfps/${rfq.id}/edit`;
      }
      else {
        return `/scout-company/rfps/${rfq.id}`;
      }
    },

    rfqName(rfq) {
      return rfq.name || `Reference #${rfq.id}`;
    },

    formatDate(rfqDate) {
      return DateFilter.filterDate(rfqDate);
    },

    isPastDue(rfq) {
      return moment().isAfter(rfq.dueDate) && ['draft', 'sent'].includes(rfq.status);
    },

    initialSortOrder(sortBy) {
      return {
        'date_sent': 'desc',
        'id': 'asc',
        'creator_full_name': 'asc',
        'assigned_client_practice_group': 'asc',
        'due_date': 'desc',
        'status': 'asc'
      }[sortBy];
    },

    sort(sortBy) {
      let sortOrder;

      if (this.order[sortBy]) {
        sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';
      }
      else {
        sortOrder = this.initialSortOrder(sortBy);
      }

      this.order = { [sortBy]: sortOrder };
      this.onSort({ sort_by: sortBy, sort_direction: sortOrder });
    },

    currentUserIsCreator(rfq) {
      return this.currentUser.id === rfq.creatorId;
    },

    hasStatus(rfq, status) {
      return rfq.status === status;
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep(.fixed-width-125px-i) ul.dropdown-menu {
    width: 125px;
    min-width: 125px;
  }
</style>
