<template>
  <priori-modal ref="listSelectModal" modal-id="rfq-from-existing-list-modal" title="Populate RFP from Existing List">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-button-compact" @click="openModal">Use Existing List</button>
    </template>

    <form role="form" class="a-form" novalidate>
      <div class="bottom-30">
        <loading-section name="rfqResourceLists">
          <dropdown-select
            ref="listDropdown"
            label="Select one of your Scout lists to populate the RFP"
            placeholder="Select One"
            id-label="rfq-existing-list"
            :options="resourceLists"
            value-key="id"
            @input="(item) => updateSelectedList(item)"
            label-key="name">
          </dropdown-select>
        </loading-section>
      </div>

      <div class="row tight-columns">
        <div class="col-sm-5">
          <button type="button" :class="['primary-btn-night-blue', { 'disabled': !listSelected }]" @click="proceedToSelectList">Select List</button>
        </div>

        <div class="col-sm-4 top-20-xs">
          <button type="button" class="secondary-btn-blue" @click="resetSelectionModal">Cancel</button>
        </div>
      </div>
    </form>
  </priori-modal>
</template>

<script>
import PrioriModal from 'src/vue-app/shared/components/priori-modal.vue';
import RFQLawFirmQuote from 'src/resources/scout/rfq-law-firm-quote.js';
import { partition } from 'lodash';

export default {
  name: 'RfqAddFromList',

  components: {
    PrioriModal
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      listSelected: false,
      selectedListId: null
    };
  },

  methods: {
    proceedToSelectList() {
      RFQLawFirmQuote.getFromResourceList({ resource_list_id: this.selectedListId, rfq_id: this.rfq.id })
        .then((quotes) => {
          const [rfqEnabledQuotes, rfqDisabledQuotes] = partition(quotes.data, 'firmHasRfqEnabled');

          this.rfq.lawFirmQuotes         = rfqEnabledQuotes;
          this.rfq.disabledLawFirmQuotes = rfqDisabledQuotes;

          this.rfq.hasImportedList = true;
        });
      this.resetSelectionModal();
    },

    updateSelectedList(listId) {
      this.selectedListId = listId;
      this.listSelected = true;
    },

    resetSelectionModal() {
      this.listSelected = false;
      this.selectedListId = null;
      this.$refs.listSelectModal.closeModal();
      this.$refs.listDropdown.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
  .center-justified {
    justify-content: center;
  }
</style>
