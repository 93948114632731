<template>
  <div>
    <div ref="reference" class="reference" @click="toggleVisibility">
      <slot name="reference"></slot>
    </div>

    <div v-show="isVisible" ref="floating" class="floating" :style="{ minWidth: minWidth }">
      <slot name="floating"></slot>
    </div>
  </div>
</template>

<script>
import { computePosition, autoUpdate, offset, flip } from '@floating-ui/dom';

export default {
  name: 'FloatingWrapper',

  props: {
    placement: {
      type: String,
      default: 'bottom'
    },

    minWidth: {
      type: String,
      default: '280px'
    }
  },

  data() {
    return {
      isVisible: false,
      floatingUiListenersCleanup: null
    };
  },

  computed: {
    floatingReference() {
      return this.$refs.reference;
    },

    floatingElement() {
      return this.$refs.floating;
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.setAutoUpdate();
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    this.floatingUiListenersCleanup();
  },

  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;

      if (this.isVisible) {
        this.$emit('open');
      }
    },

    setAutoUpdate() {
      this.floatingUiListenersCleanup = autoUpdate(this.floatingReference, this.floatingElement, () => {
        computePosition(this.floatingReference, this.floatingElement, {
          placement: this.placement,
          middleware: [offset(10), flip()]
        })
        .then(({ x, y, placement }) => {
          Object.assign(this.floatingElement.style, {
            left: `${x}px`,
            top: `${y}px`
          });

          this.floatingElement.dataset.placement = placement;
        });
      });
    },

    handleClickOutside(event) {
      if (this.isVisible && !this.floatingReference.contains(event.target) && !this.floatingElement.contains(event.target)) {
        this.isVisible = false;
      }
    }
  }
};
</script>

<style scoped>
.reference {
  display: inline-block;
}

.floating {
  position: absolute;
}
</style>
