<template>
  <div class="shadowed-box size-text-13px">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Post to Opportunities Page
        </div>

        <div v-if="hasOpportunity && !isEditing" class="col-sm-4 text-right">
          <a href="" class="normal-weight size-text-13px" @click.prevent="edit">Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div v-if="errorMessage.length" id="error-message" class="pr-error size-text-13px semibold-weight bottom-20">
        {{ errorMessage }}
      </div>

      <div v-if="!hasOpportunity && !isEditing" class="row">
        <div class="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
          <button type="button" class="nv-button-blue a-button-size" @click="edit">Create New Opportunity Post</button>
        </div>
      </div>

      <div v-else>
        <opportunity-form
          :opportunity="formOpportunity"
          :practice-areas="practiceAreas"
          :editing="isEditing"
          :on-save="onSave"
          :on-cancel="onCancel">
        </opportunity-form>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityForm from 'vue-app/admin/opportunities/form.vue';
import Opportunity from 'resources/admin/opportunity.js';
import PracticeArea from 'resources/practice-area.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import { uniq, compact } from 'lodash';

export default {
  name: 'OpportunityEditor',

  components: {
    OpportunityForm
  },

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      errorMessage: '',
      opportunityCopy: JSON.parse(JSON.stringify(this.counselRequest.opportunity)),
      defaultOpportunity: {},
      practiceAreas: []
    };
  },

  computed: {
    formOpportunity() {
      return this.opportunityCopy || this.defaultOpportunity;
    },

    hasOpportunity() {
      return !!this.opportunityCopy?.id;
    }
  },

  mounted() {
    PracticeArea.query().then(
      (practiceAreas) => {
        this.practiceAreas = practiceAreas;
        this.defaultOpportunity = (this.hasOpportunity ? {} : this.buildDefaultOpportunity());
      }
    );
  },

  methods: {
    buildDefaultOpportunity() {
      const opportunityPracticeAreas = compact(this.counselRequest.practiceAreas.map(name => {
        const selectedPa = this.practiceAreas.find(pa => name.includes(pa.name));
        if (!selectedPa) { return null; }
        return { practiceAreaId: selectedPa.id };
      }));

      const siteOptions = { 'yes': 'onsite', 'no': 'remote' };
      const timeCommitmentOptions = { 'Full-time': 'full_time', 'Part-time': 'part_time' };

      return {
        postTitle: this.counselRequest.nameForLawyersReference,
        opportunityDescription: this.counselRequest.rfpDescription,
        country: this.counselRequest.country,
        state: this.counselRequest.state,
        city: this.counselRequest.city,
        requiredBars: this.counselRequest.requiredBars || [],
        opportunityQuestions: [{ questionType: 'text', questionText: 'Please detail your relevant experience based on the project description' }],
        status: 'open',
        statusTag: null,
        rateMin: null,
        rateMax: null,
        counselRequestId: this.counselRequest.id,
        opportunityPracticeAreas: uniq(opportunityPracticeAreas),
        timeCommitment: timeCommitmentOptions[this.counselRequest.timeCommitment] || null,
        site: siteOptions[this.counselRequest.isOnsite] || null
      };
    },

    edit() {
      this.isEditing = true;
    },

    onSave(opportunityParams) {
      let promise = null;

      if (this.hasOpportunity) {
        promise = Opportunity.update({ id: this.opportunityCopy.id, ...opportunityParams });
      }
      else {
        promise = Opportunity.save(opportunityParams);
      }

      return promise
        .then((response) => {
          let status = 'saved';

          if (opportunityParams.published) {
            status = 'published';
          }
          else if (opportunityParams.published === false) {
            status = 'unpublished';
          }

          this.counselRequest.opportunity = response;
          this.opportunityCopy = response;
          this.isEditing = false;

          NotificationService.success(`Opportunity successfully ${status}.`, false);
        })
        .catch((response) => {
          NotificationService.error(response.response.data.errors.join(', '), true);
        })
        .finally(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },

    onCancel() {
      this.isEditing = false;
      this.opportunityCopy = JSON.parse(JSON.stringify(this.counselRequest.opportunity));
      this.defaultOpportunity = (this.hasOpportunity ? {} : this.buildDefaultOpportunity());
    }
  }
};
</script>
