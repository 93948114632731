<template>
  <filter-button
    :label="filterParams.label"
    :active="isActive"
    @open="open">
    <template #default>
      <filter-multiselect
        v-if="template === 'multiselect'"
        :search-service="searchService"
        :filter-params="filterParams"
        :options="options"
        :on-search="onSearch">
      </filter-multiselect>

      <filter-typeahead
        v-else-if="template === 'typeahead'"
        :search-service="searchService"
        :filter-params="filterParams"
        :options-query-function="filterParams.loadAsync ? loadFilterOptions : null"
        :options="options"
        :on-search="onSearch">
      </filter-typeahead>

      <filter-radio
        v-else-if="template === 'radio'"
        :search-service="searchService"
        :filter-params="filterParams"
        :on-search="onSearch">
      </filter-radio>

      <filter-match
        v-else-if="template === 'match'"
        :search-service="searchService"
        :filter-params="filterParams"
        :on-search="onSearch">
      </filter-match>

      <filter-keyword
        v-else-if="template === 'keyword'"
        :search-service="searchService"
        :filter-params="filterParams"
        :on-search="onSearch">
      </filter-keyword>

      <filter-range-currency
        v-else-if="template === 'range-currency'"
        :search-service="searchService"
        :filter-params="filterParams"
        :on-search="onSearch">
      </filter-range-currency>

      <filter-location
        v-else-if="template === 'location'"
        :search-service="searchService"
        :filter-params="filterParams"
        :options="options"
        :on-search="onSearch">
      </filter-location>

      <filter-range
        v-else-if="template === 'range'"
        :search-service="searchService"
        :filter-params="filterParams"
        :on-search="onSearch">
      </filter-range>
    </template>
  </filter-button>
</template>

<script>
import FilterButton from 'vue-app/shared/components/filter-button.vue';
import FilterTypeahead from 'vue-app/scout/shared/searches/popovers/filter-typeahead.vue';
import FilterMultiselect from 'vue-app/scout/shared/searches/popovers/filter-multiselect.vue';
import FilterRadio from 'vue-app/scout/shared/searches/popovers/filter-radio.vue';
import FilterMatch from 'vue-app/scout/shared/searches/popovers/filter-match.vue';
import FilterKeyword from 'vue-app/scout/shared/searches/popovers/filter-keyword.vue';
import FilterRangeCurrency from 'vue-app/scout/shared/searches/popovers/filter-range-currency.vue';
import FilterLocation from 'vue-app/scout/shared/searches/popovers/filter-location.vue';
import FilterRange from 'vue-app/scout/shared/searches/popovers/filter-range.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'SearchFilter',

  components: {
    FilterButton,
    FilterTypeahead,
    FilterMultiselect,
    FilterRadio,
    FilterMatch,
    FilterKeyword,
    FilterRangeCurrency,
    FilterLocation,
    FilterRange
  },

  props: {
    searchService: {
      type: Object,
      required: true
    },

    filterService: {
      type: Object,
      required: true
    },

    filterParams: {
      type: Object,
      required: true
    },

    onOpen: {
      type: Function,
      default: () => {}
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      label: this.filterParams.label,
      template: this.filterParams.template,
      field: this.filterParams.field,
      slug: this.filterParams.slug,
      options: this.filterParams.options || [],
      optionsLoaded: false,
      filtersWithOptions: ['multiselect', 'typeahead', 'location']
    };
  },

  computed: {
    isActive() {
      if (this.template === 'location') {
        return this.searchService.hasFilter('location') || this.searchService.hasFilter('geo_locations');
      }

      return this.searchService.hasFilter(this.field);
    }
  },

  methods: {
    open() {
      const shouldLoadOptions = this.filtersWithOptions.includes(this.template) &&
                                !this.filterParams.loadAsync &&
                                !this.optionsLoaded &&
                                !this.options.length;

      if (shouldLoadOptions) {
        this.loadFilterOptions();
      }

      this.searchService.setFilterLogic(this.field, this.filterParams.logic);
      this.onOpen();
    },

    loadFilterOptions(searchValue = null) {
      LoadingService.loading('filterOptions');

      return this.filterService.getFilterOptions(this.slug, searchValue)
        .then((response) => {
          if (!this.filterParams.loadAsync) {
            this.options = response;
          }

          this.optionsLoaded = true;
          this.disabled      = !this.options.length;
          LoadingService.done('filterOptions');

          return response;
        });
    }
  }
};
</script>
