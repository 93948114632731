<template>
  <edit-modal
    :input="rfqCopy"
    modal-size="md"
    @on-modal-open="reset"
    :on-save="save"
    save-button-text="Send"
    title="Edit RFP Details">
    <warning-message
      class="bottom-30"
      message="All law firm recipients will be notified of any changes made to this RFP.">
    </warning-message>

    <div class="row tight-columns">
      <div class="col-xs-12 bottom-30">
        <text-input-vertical
          id="rfq-name"
          label="RFP Name"
          rules="required"
          v-model="rfqCopy.name">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 bottom-30">
        <div class="bold-weight">
          RFP Submitted By <span class="inline-help required">*</span>
        </div>

        <div class="top-2">
          {{ rfqCopy.creatorFullName }}
        </div>
      </div>

      <div class="col-sm-6 bottom-30">
        <label class="bold-weight">
          Practice Group
        </label>

        <multi-select-dropdown
          v-if="rfqCopy.workspacePracticeGroups.length"
          :value="selectedPracticeGroups"
          :options="availablePracticeGroups"
          @change="selectPracticeGroups">
        </multi-select-dropdown>

        <tag-list
          v-if="rfqCopy.practiceGroups.length"
          :tags="rfqCopy.practiceGroups"
          :on-remove="removePracticeGroup"
          class="top-10">
        </tag-list>

        <text-input-vertical
          v-if="!rfqCopy.workspacePracticeGroups.length"
          id="practice-group"
          v-model="rfqCopy.assignedClientPracticeGroup">
        </text-input-vertical>
      </div>

      <div class="col-xs-12 bottom-30">
        <textarea-vertical
          id="rfq-description-of-work"
          label="Description of Work"
          rules="required"
          v-model="rfqCopy.descriptionOfWork">
        </textarea-vertical>
      </div>

      <div class="col-xs-12 bottom-30">
        <textarea-vertical
          id="rfq-counterparty-details"
          label="Any relevant opposing party/counterparty details, including counsel"
          v-model="rfqCopy.counterpartyDetails">
        </textarea-vertical>
      </div>

      <div class="col-xs-12 bottom-30">
        <textarea-vertical
          id="rfq-estimated-timeline"
          label="Estimated Timeline"
          v-model="rfqCopy.estimatedTimeline">
        </textarea-vertical>
      </div>

      <div class="col-xs-12 bottom-30">
        <label for="rfq-budget-min">Budget (if any)</label>

        <div class="row tight-columns">
          <div class="col-sm-4">
            <currency-input-vertical
              id="rfq-budget-min"
              name="budgetMin"
              placeholder="Bottom of range (if applicable)"
              :rules="validationRulesMin"
              validation-mode="eager"
              v-model.number="rfqCopy.budgetMin">
            </currency-input-vertical>
          </div>

          <div class="col-sm-1 text-center top-10 hidden-xs">
            &mdash;
          </div>

          <div class="col-sm-4 top-10-xs">
            <currency-input-vertical
              :custom-error-messages="{ minValue: `* Can't be less than bottom of range` }"
              id="rfq-budget-max"
              name="budgetMax"
              placeholder="Top of range"
              :rules="validationRulesMax"
              validation-mode="eager"
              v-model.number="rfqCopy.budgetMax">
            </currency-input-vertical>
          </div>
        </div>
      </div>

      <div class="col-xs-12 bottom-30">
        <textarea-vertical
          id="rfq-preferred-rate-structure"
          label="Preferred Rate Structure"
          v-model="rfqCopy.preferredRateStructure">
          <template #description>
            <div class="tight-lines bottom-10">
              Please describe your preferred rate structure (e.g. hourly, fixed, collared, contingent, or another alternative structure)
            </div>
          </template>
        </textarea-vertical>
      </div>

      <div class="col-xs-12 bottom-30">
        <textarea-vertical
          id="rfq-additional-information"
          label="Additional Information"
          v-model="rfqCopy.additionalInformation">
        </textarea-vertical>
      </div>

      <div class="col-xs-12 bottom-30">
        <div class="bold-weight bottom-10">
          Supporting Documents
        </div>

        <div>
          <file-selector
            :accept="acceptedExtensions"
            @change="addAttachments"
            :max-size="50"
            :multiple="true">
            <div class="secondary-btn-blue clickable width-max-content">Upload File(s)</div>
          </file-selector>
        </div>

        <div v-if="existingDocumentsExist || documentsToAddExist" class="top-20">
          <div v-if="existingDocumentsExist" class="file-list list-spacing-5">
            <div
              v-for="(attachment, index) in existingDocuments"
              class="file-item list-spacing-5"
              :key="`existing-doc-${index}`">
              <file-tag :file="attachment" :on-delete="removeExistingAttachment"></file-tag>
            </div>
          </div>

          <div v-if="documentsToAddExist">
            <div class="file-list">
              <div
                v-for="(attachment, index) in documentsToAdd"
                class="file-item list-spacing-5"
                :key="`doc-to-add-${index}`">
                <file-tag :file="attachment" :on-delete="removeNewAttachment"></file-tag>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <label for="rfq-due-date">Responses Due By <span class="red-text">*</span></label>

        <div class="row tight-columns">
          <div class="col-sm-6">
            <validation-provider rules="required" mode="eager" :immediate="hasDueDate" v-slot="{ errors, failed }">
              <div class="input-group datepicker-group" @click="toggleRenderDatePicker">
                <input
                  type="text"
                  id="rfq-due-date"
                  data-testid="rfq-due-date"
                  :class="['form-control', { 'has-error': failed }]"
                  readonly
                  :value="formatDate(rfqCopy.dueDate)">

                <div class="input-group-btn">
                  <button type="button" class="btn btn-default"><svg-icon name="calendar" class="base-icon"></svg-icon></button>
                </div>
              </div>

              <div v-if="errors.length && !renderDatePicker" class="error-text top-5">
                <error-message :errors="errors"></error-message>
              </div>
            </validation-provider>

            <styled-date-picker
              v-if="renderDatePicker"
              class="styled-date-picker top-5"
              :limit-from="limitDueDateFrom"
              :date="formatDate(rfqCopy.dueDate)"
              @input="setDueDate">
            </styled-date-picker>
          </div>
        </div>
      </div>
    </div>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above.
    </template>
  </edit-modal>
</template>

<script>
import { cloneDeep, map, without } from 'lodash';
import { ACCEPTED_FILE_EXTENSIONS } from 'vue-app/scout/shared/rfqs/constants.js';
import DateFilter from 'vue-app/shared/lib/date-filter';

import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import FileTag from 'vue-app/shared/components/file-tag.vue';
import MultiSelectDropdown from 'vue-app/shared/components/multi-select-dropdown.vue';
import StyledDatePicker from 'vue-app/shared/components/styled-date-picker.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TagList from 'vue-app/scout/shared/tag-list.vue';
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import { ValidationProvider } from 'vee-validate';
import WarningMessage from 'vue-app/shared/components/warning-message.vue';

export default {
  name: 'EditRfqProjectDetails',

  components: {
    CurrencyInputVertical,
    EditModal,
    ErrorMessage,
    FileSelector,
    FileTag,
    MultiSelectDropdown,
    StyledDatePicker,
    SvgIcon,
    TagList,
    TextareaVertical,
    TextInputVertical,
    ValidationProvider,
    WarningMessage
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    const rfqCopy = this.initializeCopy();

    return {
      rfqCopy: rfqCopy,
      existingDocuments: cloneDeep(rfqCopy.supportingDocuments),
      documentsToAdd: [],
      documentsToDelete: [],
      renderDatePicker: false
    };
  },

  computed: {
    acceptedExtensions() {
      return ACCEPTED_FILE_EXTENSIONS;
    },

    existingDocumentsExist() {
      return this.existingDocuments.length > 0;
    },

    documentsToAddExist() {
      return this.documentsToAdd.length > 0;
    },

    hasDueDate() {
      return !!this.rfqCopy.dueDate;
    },

    limitDueDateFrom() {
      const isPast = Date.parse(this.rfqCopy.dueDate) < new Date();
      return isPast ? new Date(this.rfqCopy.dueDate) : new Date();
    },

    validationRulesMin() {
      return {
        minValue: 0.01
      };
    },

    validationRulesMax() {
      return {
        minValue: this.rfqCopy.budgetMin
      };
    },

    availablePracticeGroups() {
      return this.rfq.workspacePracticeGroups.map((group) => group.name);
    },

    selectedPracticeGroups() {
      return this.rfqCopy.practiceGroups.map((group) => group.name);
    },

    workspacePracticeGroupIdMap() {
      return this.rfqCopy.workspacePracticeGroups.reduce((map, practiceGroup) => {
        map[practiceGroup.name] = practiceGroup.id;
        return map;
      }, {});
    }
  },

  methods: {
    initializeCopy() {
      const rfqCopy = cloneDeep(this.rfq);
      rfqCopy.dueDate = this.formatDate(rfqCopy.dueDate);
      rfqCopy.practiceGroupIds = this.practiceGroupIds(rfqCopy.practiceGroups);

      return rfqCopy;
    },

    reset() {
      const rfqCopy = this.initializeCopy();
      this.rfqCopy = rfqCopy;
      this.existingDocuments = cloneDeep(this.rfqCopy.supportingDocuments);
      this.documentsToAdd = [];
      this.documentsToDelete = [];
    },

    buildAttachmentsFor(values) {
      // NOTE: value is either an array of attachments (with ids) to be destroyed
      // or an array of file objects to be added as attachments
      if (!values.length || values[0].id) { return values; }
      return map(values, (file) => { return { file: file }; });
    },

    addAttachments(attachments) {
      const documents = [];
      this.buildAttachmentsFor(Array.from(attachments)).forEach(attachment => documents.push(attachment.file));
      this.documentsToAdd = this.documentsToAdd.concat(documents);
    },

    removeExistingAttachment(attachment) {
      const document = this.rfqCopy.supportingDocuments.find(document => document.id === attachment.id);
      document._destroy = true;
      this.existingDocuments = without(this.existingDocuments, attachment);
    },

    removeNewAttachment(attachment) {
      this.documentsToAdd = without(this.documentsToAdd, attachment);
    },

    toggleRenderDatePicker() {
      this.renderDatePicker = !this.renderDatePicker;
    },

    formatDate(date) {
      return DateFilter.filterDate(date);
    },

    practiceGroupIds(practiceGroups) {
      return practiceGroups.map(practiceGroup => practiceGroup.id);
    },

    selectPracticeGroups(selectedPracticeGroups) {
      const ids = [];
      const practiceGroups = [];
      selectedPracticeGroups.forEach(groupName => {
        ids.push(this.workspacePracticeGroupIdMap[groupName]);
        practiceGroups.push(
          {
            id: this.workspacePracticeGroupIdMap[groupName],
            name: groupName
          }
        );
      });

      this.rfqCopy.practiceGroupIds = ids;
      this.rfqCopy.practiceGroups = practiceGroups;
    },

    removePracticeGroup(index) {
      const removedPracticeGroup = this.rfqCopy.practiceGroups.splice(index, 1)[0];
      const removedIdIndex = this.rfqCopy.practiceGroupIds.indexOf(removedPracticeGroup.id);
      this.rfqCopy.practiceGroupIds.splice(removedIdIndex, 1);
    },

    setDueDate(date) {
      this.rfqCopy.dueDate = date;
      this.renderDatePicker = false;
    },

    buildDocumentData() {
      this.documentsToDelete.forEach(document => {
        this.rfqCopy.supportingDocuments.find(attachment => attachment.id === document.id)._destroy = true;
      });

      this.rfqCopy.supportingDocuments = this.rfqCopy.supportingDocuments.concat(
        this.buildAttachmentsFor(this.documentsToAdd)
      );
    },

    save() {
      this.buildDocumentData();
      this.onSave(this.rfqCopy);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .styled-date-picker {
    position: absolute;
    z-index: 1000;
  }

  .a-form .form-control[readonly] {
    background: $white;
  }
</style>
