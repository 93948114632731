<template>
  <priori-modal ref="modal" :title="statusChangeText(rfqStatusOnOpen)" @on-modal-open="setStatusOnOpen">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">{{ statusChangeText(rfq.status) }}</a>
    </template>

    <template #default="{ closeModal }">
      <div class="size-text-14px bottom-30">
        Are you sure you want to {{ confirmationStatusText }} RFP?
      </div>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <loading-button name="handleStatusChange" lb-class="primary-btn-blue" @lb-click="handleStatusChange">
            {{ statusChangeText(rfqStatusOnOpen) }}
          </loading-button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'ChangeRfqStatusModal',

  components: {
    PrioriModal
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    changeRfqStatus: {
      type: Function,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      // Immediately after the RFQ has its status changed, but before the modal
      // is closed, the text in the modal switches, which is jarring for the
      // user. In order to prevent this, we need to keep track of what the
      // status was at the time the modal was opened.
      rfqStatusOnOpen: ''
    };
  },

  computed: {
    confirmationStatusText() {
      if (this.rfqStatusOnOpen === 'draft') {
        return 'delete this draft';
      }
      else if (this.rfqStatusOnOpen !== 'closed') {
        return 'close this';
      }
      else {
        return 'reopen this';
      }
    }
  },

  methods: {
    setStatusOnOpen() {
      this.rfqStatusOnOpen = this.rfq.status;
    },

    statusChangeText(status) {
      if (status === 'draft') {
        return 'Delete Draft';
      }
      else if (status !== 'closed') {
        return 'Close RFP';
      }
      else {
        return 'Reopen RFP';
      }
    },

    handleStatusChange() {
      let promise;

      LoadingService.loading('handleStatusChange');

      if (this.rfq.status === 'draft') {
        promise = this.onDelete({ id: this.rfq.id });
      }
      else {
        promise = this.changeRfqStatus(this.rfq);
      }

      promise.then(() => {
        LoadingService.done('handleStatusChange');
        this.$refs.modal.closeModal();
      });
    }
  }
};
</script>
