<template>
  <div :class="['color-tag', 'bold-weight', 'size-text-12px', colorPattern]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ColorTag',

  props: {
    colorPattern: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";
  @import "stylesheets/marketplace/variables";

  .color-tag {
    border-radius: $border-radius-small;
    display: inline-block;
    padding: 2px 24px;
    width: fit-content;

    &.purple {
      background-color: $k-purple;
      color: $white;
    }

    &.light-purple {
      background-color: $marketplace-tag-bg-purple;
      color: $marketplace-tag-font-purple;
    }
  }
</style>
