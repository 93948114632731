<template>
  <priori-modal ref="modal" :title="`Request Interview: ${ providerName }`">
    <template #modal-trigger="{ openModal }">
      <button
        type="button"
        :disabled="interviewRequested"
        @click="openModal"
        :class="['icon-button nv-button-white flex-column', { 'selected': interviewRequested }]">
        <svg-icon name="checkmark" class="base-icon"></svg-icon>
        <span class="top-5">{{ interviewRequested ? 'Requested' : 'Interview' }}</span>
      </button>
    </template>

    <template #default="{ closeModal }">
      <div class="bottom-20">
        To interview this Priori Marketplace Network provider, please confirm your selection below. Following confirmation, a Priori representative will reach out with next steps.
      </div>

      <div class="bottom-30">
        Are you sure you&rsquo;d like to proceed?
      </div>

      <div class="row tight-columns">
        <div class="col-sm-6">
          <loading-button name="updateQuote" lb-class="primary-btn-blue" @lb-click="requestInterview">
            Request Interview
          </loading-button>
        </div>

        <div class="col-sm-3 top-10-xs">
          <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'InterviewButton',

  components: {
    PrioriModal,
    SvgIcon
  },

  props: {
    selectedQuote: {
      type: Object,
      required: true
    },

    interviewRequested: {
      type: Boolean,
      default: false
    },

    rfq: {
      type: Object,
      required: true
    },

    updateQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    providerName() {
      return this.selectedQuote.fromMarketplace ? this.marketplaceProviderName : this.selectedQuote.lawFirm.name;
    },

    marketplaceProviderName() {
      return this.selectedQuote.marketplaceLawyer.addedAsFirm ? this.selectedQuote.lawFirm.name : this.selectedQuote.marketplaceLawyer.fullName;
    }
  },

  methods: {
    requestInterview() {
      LoadingService.loading('updateQuote');

      return this.updateQuote(this.rfq, this.selectedQuote, 'requested_interview').then(() => {
        LoadingService.done('updateQuote');
        this.$refs.modal.closeModal();
      });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 100%;

    @media (min-width: $screen-sm-min) {
      width: 90px;
    }

    @media (min-width: 1055px) {
      width: 100px;
    }

    @media (min-width: $screen-lg-min) {
      width: 110px;
    }

    &:disabled, &:disabled .base-icon {
      opacity: 1;
    }
  }

  .selected {
    color: $scout-green;
    fill: $scout-green;
    background-color: $green-10;
    border-color: $scout-green;

    svg {
      fill: $scout-green;
    }
  }
</style>
